import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "liquidity-provider-fees",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#liquidity-provider-fees",
        "aria-label": "liquidity provider fees permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Liquidity provider fees`}</h2>
    <p>{`There is a `}<strong parentName="p">{`0.3%`}</strong>{` fee for swapping tokens. `}<strong parentName="p">{`This fee is split by liquidity providers proportional to their contribution to liquidity reserves.`}</strong></p>
    <p>{`Swapping fees are immediately deposited into liquidity reserves. This increases the value of liquidity tokens, functioning as a payout to all liquidity providers proportional to their share of the pool. Fees are collected by burning liquidity tokens to remove a proportional share of the underlying reserves.`}</p>
    <p>{`Since fees are added to liquidity pools, the invariant increases at the end of every trade. Within a single transaction, the invariant represents `}<inlineCode parentName="p">{`token0_pool * token1_pool`}</inlineCode>{` at the end of the previous transaction.`}</p>
    <p>{`There are many community developed tools to determine returns. You can also read more in the docs about how to think about `}<a parentName="p" {...{
        "href": "/docs/v2/advanced-topics/understanding-returns/"
      }}>{`LP returns`}</a>{`.`}</p>
    <h2 {...{
      "id": "protocol-fees",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#protocol-fees",
        "aria-label": "protocol fees permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Protocol Fees`}</h2>
    <p>{`At the moment there are no protocol fees. However, it is possible for a 0.05% fee to be turned on in the future.`}</p>
    <p>{`More information about a potential future protocol fee can be found `}<a parentName="p" {...{
        "href": "https://uniswap.org/blog/uniswap-v2/#path-to-sustainability"
      }}>{`here`}</a>{`.`}</p>
    <h2 {...{
      "id": "protocol-charge-calculation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#protocol-charge-calculation",
        "aria-label": "protocol charge calculation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Protocol Charge Calculation`}</h2>
    <p>{`In the future, it is possible that a protocol-wide charge of 0.05% per trade will take effect. This represents ⅙th (16.6̅%) of the 0.30% fee. The fee is in effect if `}<Link to='/docs/v2/smart-contracts/factory/#feeto' mdxType="Link">{`feeTo`}</Link>{` is not `}<inlineCode parentName="p">{`address(0)`}</inlineCode>{` (`}<inlineCode parentName="p">{`0x0000000000000000000000000000000000000000`}</inlineCode>{`), indicating that feeTo is the recipient of the charge.`}</p>
    <p>{`This amount would not affect the fee paid by traders, but would affect the amount received by liquidity providers.`}</p>
    <p>{`Rather than calculating this charge on swaps, which would significantly increase gas costs for all users, the charge is instead calculated when liquidity is added or removed. See the `}<a href='/whitepaper.pdf' target='_blank' rel='noopener noreferrer'>{`whitepaper`}</a>{` for more details.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      